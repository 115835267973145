// Libraries

import React from "react"
import { TweenLite } from "gsap/TweenMax"
import BezierEasing from "bezier-easing"
import ReactHtmlParser from "react-html-parser"
import Img from "gatsby-image"
import vhCheck from "vh-check"

// Components

import Logo from "../../icons/logo.svg"

// Helpers

import { mobileSize, desktopSize } from "../../helpers/sizeCalculations"

// Styles

import styled from "styled-components"

/*-----------------------------
Styles
-----------------------------*/

/*--- Structure ---*/

const Hero = styled.div``

const HeroContainer = styled.div`
  position: relative;
  height: calc(100vh - ${desktopSize(20)});

  @media all and (max-width: 768px) {
    height: calc(100vh - ${props => props.browserBarHeight}px);
  }

  @media all and (max-width: 768px) and (orientation: landscape) {
    height: 200vh;
  }
`

/*--- Main Head ---*/

// Title

const HeroMainTitle = styled.h1`
  position: absolute;
  opacity: 0;
  left: ${desktopSize(27)};
  bottom: ${desktopSize(20)};
  transform: translateY(${desktopSize(20)});
  margin-bottom: 0;
  @media all and (max-width: 768px) {
    top: ${mobileSize(11)};
    left: ${mobileSize(11)};
    bottom: auto;
  }
`

// Bird

const HeroMainBird = styled.div`
  position: absolute;
  display: block;
  left: ${desktopSize(344)};
  bottom: ${desktopSize(0)};
  transform: translateY(${desktopSize(-200)});
  img{
    position: relative !important;
    top: 33px !important;
    left: -20px !important;
    width: auto !important;
    height: auto !important;
  }
  @media all and (max-width: 768px) {
    left: -10px;
    bottom: 20px;
    img{
      top: 0px !important;
      left: 0px !important;
    }
    width: ${mobileSize(300)};
    max-width: 350px;
  }
`

/*--- Side ---*/

const HeroLogo = styled.div`
  position: absolute;
  bottom: ${desktopSize(27)};
  right: ${desktopSize(59)};
  opacity: 0;

  @media all and (max-width: 768px) {
    right: ${mobileSize(14)};
    bottom: 20px;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    position: absolute;
    width: ${desktopSize(162)};
    bottom: ${desktopSize(27)};
    right: ${desktopSize(59)};
    padding-top: 4%;
  }

  
  svg {
    display: block;
    width: ${desktopSize(162)};
    height: auto;
    max-height: 100%;

    @media all and (max-width: 768px) {
      width: ${mobileSize(168)};
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
    }
  }

  .svg-fill {
    fill: ${props => props.theme.color.pink};
  }
`

/*--- Horizontal Line ---*/

const HorizontalLineContainer = styled.div`
  width: 100%;
  padding: 0 ${desktopSize(105)} 0 ${desktopSize(132)};
  @media all and (max-width: 768px) {
    padding: 0 ${mobileSize(12)} 0 ${mobileSize(12)};
  }
`

const HorizontalLine = styled.div`
  width: 100%;
  background: ${props => props.theme.color.white};
  height: 2px;
`

/*-----------------------------
Component
-----------------------------*/

class ChapterOneHero extends React.Component {
  constructor(props) {
    super(props)
    // State
    this.state = {
      browserBarHeight: 0,
    }
    // Refs
    this.heroMainTitle = React.createRef()
    this.heroLogo = React.createRef()
    this.heroBird = React.createRef()
  }

  /*-----------------------------
  Lifecycle
  -----------------------------*/

  componentDidMount() {
    // Check viewport height.
    const vhInfo = vhCheck()
    this.setState({
      browserBarHeight: vhInfo.value,
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.chapterIsActive !== prevProps.chapterIsActive) {
      // If the chapter is initially loaded then transition the hero elements.
      if (this.props.chapterIsActive === true) {
        // Main Title
        TweenLite.to(this.heroMainTitle.current, 1.25, {
          ease: BezierEasing(0.25, 0.1, 0.25, 1),
          opacity: 1,
          transform: "translateY(0)",
          delay: 0.7,
        })
        // Side Title
        TweenLite.to(this.heroLogo.current, 1.25, {
          ease: BezierEasing(0.25, 0.1, 0.25, 1),
          opacity: 1,
          delay: 0.45,
        })
        // Bird
        TweenLite.to(this.heroBird.current, 1.25, {
          ease: BezierEasing(0.25, 0.1, 0.25, 1),
          transform: "translateY(0)",
        })
      }
    }
  }

  /*-----------------------------
  Render
  -----------------------------*/

  render() {
    return (
      <Hero>
        <HeroContainer browserBarHeight={this.state.browserBarHeight}>
          <HeroMainTitle ref={this.heroMainTitle}>
            {ReactHtmlParser(this.props.data.title)}
          </HeroMainTitle>
          <HeroMainBird
            browserBarHeight={this.state.browserBarHeight}
            ref={this.heroBird}
          >
            <Img
              fluid={this.props.data.bird_image.localFile.childImageSharp.fluid}
              loading="eager"
              alt={this.props.data.bird_image.alt_text}
            />
          </HeroMainBird>
          <HeroLogo
            ref={this.heroLogo}
            browserBarHeight={this.state.browserBarHeight}
          >
            <Logo />
          </HeroLogo>
        </HeroContainer>
        <HorizontalLineContainer>
          <HorizontalLine />
        </HorizontalLineContainer>
      </Hero>
    )
  }
}

export default ChapterOneHero
