// Libraries

import React from "react"
import { TimelineLite, TweenLite } from "gsap/TweenMax"
import BezierEasing from "bezier-easing"
import { Link } from "gatsby"

// Components

import ChapterOneHero from "./ChapterOneHero"
import QuoteTwo from "./QuoteTwo"
import Commitment from "./Commitment"
import InvestmentApproach from "./InvestmentApproach"
import QuoteOne from "./QuoteOne"

// Helpers

import { isDesktop } from "../../helpers/mediaQueryChecks"
import { mobileSize, desktopSize } from "../../helpers/sizeCalculations"

// Styles

import styled from "styled-components"
import { rgba } from "polished"

/*-----------------------------
Styles
-----------------------------*/

const ChapterContainer = styled.div`
  width: ${desktopSize(1205)};
  position: absolute;
  right: 120vw;
  @media all and (max-width: 768px) {
    width: 100vw;
  }
`

const ChapterWrapper = styled.div`
  position: relative;
  background-color: ${props =>
    props.theme.color.green && rgba(props.theme.color.green, 0.94)};
  z-index: 3;
  margin-bottom: ${props => props.windowHeight || 0}px;
  padding: 0 0 ${desktopSize(30)} 0;
  color: ${props => props.theme.color.pink};
  overflow-y: hidden;

  @media all and (max-width: 768px) {
    padding: 0 0 ${mobileSize(30)} 0;
  }
`

// Nav

const ChapterNav = styled.div`
  position: absolute;
  right: 7.77vw;
  top: 1.527vw;
  z-index: 1;
  @media all and (max-width: 768px) {
    display: none;
  }
`

const ChapterNavItem = styled(props => <Link {...props} />)`
  display: block;
  color: ${props => props.theme.color.pink};
  text-decoration: none;
  font-size: ${desktopSize(21)};
  line-height: 1.52;
  font-family: "gt-walsheim-regular";

  &:hover {
    text-decoration: underline;
  }
`

/*-----------------------------
Component
-----------------------------*/

class ChapterOne extends React.Component {
  constructor(props) {
    super(props)
    // State
    this.state = {
      chapterIsActive: false,
    }
    // Refs
    this.chapterContainer = React.createRef()
    this.chapterNav = React.createRef()
  }

  /*-----------------------------
  Lifecycle
  -----------------------------*/

  componentDidMount() {
    // Transition the chapter into the screen on page load.
    if (this.props.animationStatus === "entered") {
      this.animateInitialLoad()
    }
    // Media Query Resize Event
    const mql = window.matchMedia("(max-width: 768px)")
    mql.addListener(this.updateChapterPositionOnResize.bind(this))
  }

  componentDidUpdate(prevProps) {
    if (this.props.animationStatus !== prevProps.animationStatus) {
      // Perform different animations based on the status of the current page transition.
      // Only animation for entering and exiting. The entered status should only trigger our animation on initial mount / first ever page load.
      switch (this.props.animationStatus) {
        case "entering":
          this.animateEntrance()
          break
        case "exiting":
          this.animateExit()
          break
        default:
      }
    }
  }

  /*-----------------------------
  Animations
  -----------------------------*/

  // Initial Load

  animateInitialLoad() {
    // Chapter transition in. Two fold effect.
    var tl = new TimelineLite()
    // Move in from left and stay in horizontal line
    tl.fromTo(
      this.chapterContainer.current,
      1.25,
      { right: "120vw", top: desktopSize(418) },
      {
        ease: BezierEasing(0.25, 0.1, 0.25, 1),
        right: `${isDesktop ? desktopSize(51) : "0vw"}`,
        top: desktopSize(418),
        delay: 1.1,
        onComplete: () => {
          this.setState({
            chapterIsActive: true,
          })
        },
      }
    )
    // Move up to final position.
    tl.to(this.chapterContainer.current, 1.25, {
      ease: BezierEasing(0.25, 0.1, 0.25, 1),
      top: `${isDesktop ? desktopSize(20) : "0vw"}`,
    })
    // Chapter Nav
    TweenLite.fromTo(
      this.chapterNav.current,
      0.8,
      { top: desktopSize(-30), opacity: 0 },
      {
        ease: BezierEasing(0.25, 0.1, 0.25, 1),
        top: desktopSize(22),
        opacity: 1,
        delay: 2.8,
      }
    )
  }

  // Entering From A Different Route

  animateEntrance() {
    // Indicate that the chapter is initially loaded so hero content appears once the route is transitioned in.
    this.setState({
      chapterIsActive: true,
    })
    // Perform the entrance transition.
    const chapterContainerNode = this.chapterContainer.current
    TweenLite.fromTo(
      chapterContainerNode,
      1.4,
      {
        right: "120vw",
        left: "auto",
        top: `${isDesktop ? desktopSize(20) : "0vw"}`,
      },
      {
        ease: BezierEasing(0.25, 0.1, 0.25, 1),
        right: `${isDesktop ? desktopSize(51) : "0vw"}`,
        delay: 1.5,
      }
    )
  }

  // Exiting To Another Route

  animateExit() {
    // Animate straight horizontal out.
    TweenLite.to(this.chapterContainer.current, 1, {
      ease: BezierEasing(0.25, 0.1, 0.25, 1),
      right: "100vw",
    })
  }

  // Update Chapter Position - On Resize

  updateChapterPositionOnResize(e) {
    const chapterContainerNode = this.chapterContainer.current
    if (chapterContainerNode) {
      // If on mobile then transition to mobile styling.
      if (e.matches) {
        TweenLite.to(chapterContainerNode, 0.3, {
          ease: BezierEasing(0.25, 0.1, 0.25, 1),
          top: "0vw",
          right: "0vw",
        })
      }
      // If on desktop then transition to desktop styling.
      else {
        TweenLite.to(chapterContainerNode, 0.3, {
          ease: BezierEasing(0.25, 0.1, 0.25, 1),
          top: desktopSize(20),
          right: desktopSize(51),
        })
      }
    }
  }

  /*-----------------------------
  Render
  -----------------------------*/

  render() {
    return (
      <ChapterContainer ref={this.chapterContainer}>
        <ChapterWrapper windowHeight={this.props.windowHeight}>
          <ChapterNav ref={this.chapterNav}>
            <ChapterNavItem to="/larry-ellisons-story">
              Larry Ellison's story
            </ChapterNavItem>
            <ChapterNavItem to="/current-investments">
              Current Investments
            </ChapterNavItem>
            <ChapterNavItem to="/previous-investments">
              Previous Investments
            </ChapterNavItem>
          </ChapterNav>
          <ChapterOneHero
            chapterIsActive={this.state.chapterIsActive}
            data={this.props.data.hero}
          />
          {/** TODO - change the citation prop when field is created in WP dashboard */}
          <QuoteTwo
            data={this.props.data.quote_1}
            quoteData={this.props.data.quote_2}
          />
          <Commitment data={this.props.data.commitment} />
          <QuoteOne data={this.props.data.quote_2} />
          <InvestmentApproach data={this.props.data.investment_approach} />
        </ChapterWrapper>
      </ChapterContainer>
    )
  }
}

export default ChapterOne
