// Libraries

import React from "react"
import { TweenLite, TimelineLite } from "gsap/TweenMax"
import BezierEasing from "bezier-easing"

// Components

import ChapterThreeHero from "./ChapterThreeHero"
import Opportunities from "./Opportunities"
import ThreePanelCarousel from "./ThreePanelCarousel"
import Learning from "./Learning"
import Footer from "./Footer"

// Helpers

import { isDesktop } from "../../helpers/mediaQueryChecks"
import { mobileSize, desktopSize } from "../../helpers/sizeCalculations"

// Styles

import styled from "styled-components"
import { rgba } from "polished"

/*-----------------------------
Styles
-----------------------------*/

const ChapterContainer = styled.div`
  width: ${desktopSize(1087)};
  position: absolute;
  z-index: 1;
  right: -100vw;
  @media all and (max-width: 768px) {
    width: 100vw;
  }
`

const ChapterWrapper = styled.div`
  position: relative;
  background-color: ${props =>
    props.theme.color.pink && rgba(props.theme.color.pink, 1)};
  z-index: 1;
  padding: 0 0 ${desktopSize(30)} 0;
  color: ${props => props.theme.color.burgundy};
  @media all and (max-width: 768px) {
    padding: 0 0 ${mobileSize(30)} 0;
  }
`

/*-----------------------------
Component
-----------------------------*/

class ChapterThree extends React.Component {
  constructor(props) {
    super(props)
    // State
    this.state = {
      chapterIsInitiallyLoaded: false,
    }
    // Refs
    this.chapterContainer = React.createRef()
  }

  /*-----------------------------
  Lifecycle
  -----------------------------*/

  componentDidMount() {
    // Transition the chapter into the screen on page load.
    if (this.props.animationStatus === "entered") {
      this.animateInitialLoad()
    }
    // Media Query Resize Event
    const mql = window.matchMedia("(max-width: 768px)")
    mql.addListener(this.updateChapterPositionOnResize.bind(this))
  }

  componentDidUpdate(prevProps) {
    if (this.props.animationStatus !== prevProps.animationStatus) {
      // Perform different animations based on the status of the current page transition.
      // Only animation for entering and exiting. The entered status should only trigger our animation on initial mount / first ever page load.
      switch (this.props.animationStatus) {
        case "entering":
          this.animateEntrance()
          break
        case "exiting":
          this.animateExit()
          break
        default:
      }
    }
  }

  /*-----------------------------
  Animations
  -----------------------------*/

  // Initial Load

  animateInitialLoad() {
    // Chapter transition in. Two fold effect.
    var tl = new TimelineLite()
    // Move in from right and stay in horizontal line
    tl.fromTo(
      this.chapterContainer.current,
      1.25,
      { right: "-100vw", top: desktopSize(100) },
      {
        ease: BezierEasing(0.25, 0.1, 0.25, 1),
        right: "0vw",
        top: desktopSize(100),
        delay: 1,
      }
    )
    // Move up to final position.
    tl.to(this.chapterContainer.current, 1.25, {
      ease: BezierEasing(0.25, 0.1, 0.25, 1),
      onStart: () => {
        this.setState({
          chapterIsInitiallyLoaded: true,
        })
      },
      top: `${isDesktop ? desktopSize(50) : "0vw"}`,
    })
  }

  // Entering From A Different Route

  animateEntrance() {
    // Indicate that the chapter is initially loaded so content appears once the route is transitioned in.
    this.setState({
      chapterIsInitiallyLoaded: true,
    })
    // Perform the entrance transition.
    const chapterContainerNode = this.chapterContainer.current
    TweenLite.fromTo(
      chapterContainerNode,
      1,
      {
        right: "100vw",
        left: "auto",
        top: `${isDesktop ? desktopSize(50) : "0vw"}`,
      },
      {
        ease: BezierEasing(0.25, 0.1, 0.25, 1),
        right: "0vw",
        delay: 1.5,
      }
    )
  }

  // Exiting To Another Route

  animateExit() {
    // Animate straight horizontal out.
    TweenLite.to(this.chapterContainer.current, 1.2, {
      ease: BezierEasing(0.25, 0.1, 0.25, 1),
      right: "100vw",
    })
  }

  // Update Chapter Position - On Resize

  updateChapterPositionOnResize(e) {
    const chapterContainerNode = this.chapterContainer.current
    if (chapterContainerNode) {
      // If on mobile then transition to mobile styling.
      if (e.matches) {
        TweenLite.to(chapterContainerNode, 0.3, {
          ease: BezierEasing(0.25, 0.1, 0.25, 1),
          top: "0vw",
        })
      }
      // If on desktop then transition to desktop styling.
      else {
        TweenLite.to(chapterContainerNode, 0.3, {
          ease: BezierEasing(0.25, 0.1, 0.25, 1),
          top: desktopSize(50),
        })
      }
    }
  }

  /*-----------------------------
  Render
  -----------------------------*/

  render() {
    /*-----------------------------
    Investment Post Data
    -----------------------------*/
    const investmentCarouselData = this.props.data.investment_carousel
    // Save all of the investment posts available.
    const allInvestmentPosts = this.props.allInvestmentPosts

    // Save the investment post data for this chapter.
    // Original format from graphql will be an array of just post ids. We need the post objects.
    const investmentPosts = investmentCarouselData.investment_posts.map(
      post => {
        // Find the full data object for this post
        const postObject = allInvestmentPosts.find(allInvestmentPost => {
          // If the id of this post matches the id of our desired post then this is the correct one.
          return allInvestmentPost.wordpress_id === post.investment_post
        })
        // Save the post object
        return postObject
      }
    )

    return (
      <ChapterContainer ref={this.chapterContainer}>
        <ChapterWrapper>
          <ChapterThreeHero
            chapterIsActive={this.props.chapterIsActive}
            data={this.props.data.hero}
          />
          <Opportunities data={this.props.data.opportunities} />
          <ThreePanelCarousel
            headerTitle={investmentCarouselData.title}
            slides={investmentPosts}
            chapter="three"
          />
          <Learning data={this.props.data.learning} />
        </ChapterWrapper>
        <Footer animationStatus={this.props.animationStatus} />
      </ChapterContainer>
    )
  }
}

export default ChapterThree
