// Libraries

import React from "react"
import ReactHtmlParser from "react-html-parser"
import Img from "gatsby-image"

// Components

// Helpers

import { mobileSize, desktopSize } from "../../helpers/sizeCalculations"

// Styles

import styled from "styled-components"

/*-----------------------------
Styles
-----------------------------*/

const Wrapper = styled.div`
  position: relative;
`

/*--- Top ---*/

const MainTop = styled.div`
  display: flex;
`

const MainTopTitleContainer = styled.div`
  position: relative;
  width: 57%;
  padding: ${desktopSize(110)} 0;
  margin: 0 0 0 auto;
  @media all and (max-width: 768px) {
    width: 76%;
    margin: 0 0 0 auto;
    padding: ${mobileSize(112)} 0;
  }
`

const MainTopTitle = styled.h2`
  position: relative;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  white-space: nowrap;
`

const MainTopImage = styled.div`
  position: absolute;
  width: ${desktopSize(216)};
  left: ${desktopSize(272)};
  top: ${desktopSize(143)};

  @media all and (max-width: 768px) {
    width: ${mobileSize(108)};
    left: ${mobileSize(0)};
    top: ${mobileSize(91)};
  }
`

/*--- Bottom ---*/

const MainBottom = styled.div`
  position: relative;
  display: flex;
`

const MainBottomContentContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 43%;
  align-items: flex-end;
  padding: 0 ${desktopSize(27)} 0 0;
  @media all and (max-width: 768px) {
    width: 76%;
    margin: 0 0 0 auto;
    padding: 0 ${mobileSize(14)} ${mobileSize(339)} ${mobileSize(13)};
  }
`

const MainBottomContent = styled.div`
  width: ${desktopSize(322)};
  @media all and (max-width: 768px) {
    width: 100%;
  }
`

const MainBottomImage = styled.div`
  position: absolute;
  width: ${desktopSize(590)};
  right: 0;
  top: 0;

  @media all and (max-width: 768px) {
    width: ${mobileSize(295)};
    top: auto;
    bottom: ${mobileSize(30)};
  }
`

/*--- Horizontal Line ---*/

const HorizontalLineContainer = styled.div`
  width: 100%;
  padding: ${desktopSize(30)} ${desktopSize(59)} 0 ${desktopSize(60)};
  @media all and (max-width: 768px) {
    padding: ${mobileSize(30)} ${mobileSize(12)} 0 ${mobileSize(12)};
  }
`

const HorizontalLine = styled.div`
  width: 100%;
  background: ${props => props.theme.color.white};
  height: 2px;
`

/*--- Vertical Line ---*/

const VerticalLineContainer = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  right: 57%;
  padding: ${desktopSize(30)} 0;

  @media all and (max-width: 768px) {
    right: 76%;
    padding: ${mobileSize(30)} 0;
  }
`

const VerticalLine = styled.div`
  width: 2px;
  background: ${props => props.theme.color.white};
  height: 100%;
`

/*-----------------------------
Component
-----------------------------*/

class Learning extends React.Component {
  constructor(props) {
    super(props)
    // State
    this.state = {}
  }

  componentDidMount() {}

  /*-----------------------------
  Render
  -----------------------------*/

  render() {
    return (
      <Wrapper>
        <MainTop>
          <MainTopImage>
            <Img
              fluid={
                this.props.data.secondary_image.localFile.childImageSharp.fluid
              }
              alt={this.props.data.secondary_image.alt_text}
            />
          </MainTopImage>
          <MainTopTitleContainer>
            <MainTopTitle>
              {ReactHtmlParser(this.props.data.title)}
            </MainTopTitle>
          </MainTopTitleContainer>
        </MainTop>
        <MainBottom>
          <MainBottomContentContainer>
            <MainBottomContent className="wysiwyg">
              {ReactHtmlParser(this.props.data.text)}
            </MainBottomContent>
          </MainBottomContentContainer>
          <MainBottomImage>
            <Img
              fluid={
                this.props.data.primary_image.localFile.childImageSharp.fluid
              }
              alt={this.props.data.primary_image.alt_text}
            />
          </MainBottomImage>
        </MainBottom>
        <HorizontalLineContainer>
          <HorizontalLine />
        </HorizontalLineContainer>
        <VerticalLineContainer>
          <VerticalLine />
        </VerticalLineContainer>
      </Wrapper>
    )
  }
}

export default Learning
