// Libraries

import React from "react"
import ReactHtmlParser from "react-html-parser"
import Img from "gatsby-image"

// Components

// Helpers

import { mobileSize, desktopSize } from "../../helpers/sizeCalculations"

// Styles

import styled from "styled-components"

/*-----------------------------
Styles
-----------------------------*/

const Wrapper = styled.div`
  position: relative;
`

/*--- Content ---*/

const ContentContainer = styled.div`
  display: flex;
  position: relative;
  z-index: 3;
  @media all and (max-width: 768px) {
    display: block;
    width: 76%;
    margin: 0 0 0 auto;
  }
`

// Title

const ContentTitleContainer = styled.div`
  position: relative;
  width: 59.8%;
  padding: 25vw 2vw 20vw 0;
  padding: ${desktopSize(348)} ${desktopSize(28)} 0 0;
  @media all and (max-width: 768px) {
    padding: ${mobileSize(112)} ${mobileSize(17.5)} 0 ${mobileSize(12.5)};
    width: 100%;
  }
`

const ContentTitle = styled.div`
  width: 50%;
  text-align: center;
  margin: 0 0 0 auto;
  @media all and (max-width: 768px) {
    width: 100%;
  }

  h2 {
    margin-bottom: 0;
    white-space: nowrap;
  }
`

// Text

const ContentText = styled.div`
  position: relative;
  width: 40.2%;
  padding: ${desktopSize(454)} ${desktopSize(134)} 0 ${desktopSize(28)};

  @media all and (max-width: 768px) {
    width: 100%;
    padding: ${mobileSize(248)} ${mobileSize(14)} 0 ${mobileSize(13)};
  }
`

/*--- Horizontal Line ---*/

const HorizontalLineContainer = styled.div`
  width: 100%;
  padding: ${desktopSize(30)} ${desktopSize(105)} 0 ${desktopSize(132)};
  @media all and (max-width: 768px) {
    padding: ${mobileSize(30)} ${mobileSize(12)} 0 ${mobileSize(12)};
  }
`

const HorizontalLine = styled.div`
  width: 100%;
  background: ${props => props.theme.color.white};
  height: 2px;
`

/*--- Vertical Line ---*/

const VerticalLineContainer = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  right: 40.2%;
  padding: ${desktopSize(30)} 0;
  @media all and (max-width: 768px) {
    padding: ${mobileSize(30)} 0;
    right: 76%;
  }
`

const VerticalLine = styled.div`
  width: 2px;
  background: ${props => props.theme.color.white};
  height: 100%;
`

/*--- Absolute Images ---*/

const PortraitImage = styled.div`
  position: absolute;
  width: ${desktopSize(168)};
  right: ${desktopSize(340)};
  top: ${desktopSize(65)};
  @media all and (max-width: 768px) {
    width: ${mobileSize(82.5)};
    top: ${mobileSize(362)};
    right: ${mobileSize(45.5)};
    height: auto;
  }
`

const LongImagePortrait = styled.div`
  position: absolute;
  top: 0;
  width: ${desktopSize(326)};
  left: ${desktopSize(131)};
  height: 100%;
  padding: ${desktopSize(31)} 0;
  .gatsby-image-wrapper {
    height: 100%;
    width: auto;
  }
  img {
    margin: 0;
    width: auto;
    height: 100%;
  }
  @media all and (max-width: 768px) {
    width: ${mobileSize(163)};
    top: ${mobileSize(43)};
    left: 0;
    height: auto;
  }
`

/*-----------------------------
Component
-----------------------------*/

class InvestmentApproach extends React.Component {
  constructor(props) {
    super(props)
    // State
    this.state = {}
  }

  componentDidMount() {}

  /*-----------------------------
  Render
  -----------------------------*/

  render() {
    return (
      <Wrapper>
        <ContentContainer>
          <ContentTitleContainer>
            <ContentTitle>
              <h2>{ReactHtmlParser(this.props.data.title)}</h2>
            </ContentTitle>
          </ContentTitleContainer>
          <ContentText className="wysiwyg">
            {ReactHtmlParser(this.props.data.text)}
          </ContentText>
        </ContentContainer>
        <HorizontalLineContainer>
          <HorizontalLine />
        </HorizontalLineContainer>
        <PortraitImage>
          <Img
            fluid={
              this.props.data.secondary_image.localFile.childImageSharp.fluid
            }
            alt={this.props.data.secondary_image.alt_text}
          />
        </PortraitImage>
        <LongImagePortrait>
          <Img
            fluid={
              this.props.data.primary_image.localFile.childImageSharp.fluid
            }
            alt={this.props.data.primary_image.alt_text}
          />
        </LongImagePortrait>
        <VerticalLineContainer>
          <VerticalLine />
        </VerticalLineContainer>
      </Wrapper>
    )
  }
}

export default InvestmentApproach
