// Libraries

import React from "react"
import ReactHtmlParser from "react-html-parser"
import Img from "gatsby-image"

// Components

// Helpers

import { mobileSize, desktopSize } from "../../helpers/sizeCalculations"

// Styles

import styled from "styled-components"

/*-----------------------------
Styles
-----------------------------*/

const Wrapper = styled.div`
  position: relative;
`

/*--- Main ---*/

const Main = styled.div`
  position: relative;
`

const ContentContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: ${desktopSize(355)} ${desktopSize(30)} 0 0;
  width: 71%;
  @media all and (max-width: 768px) {
    width: 75.6%;
    padding: ${mobileSize(357)} ${mobileSize(12.5)} 0 ${mobileSize(12)};
  }
`

const Content = styled.div`
  width: ${desktopSize(323)};
  text-align: left;
  margin: 0;
  float: right;
  @media all and (max-width: 768px) {
    width: 100%;
  }
`

/*--- Vertical Line ---*/

const VerticalLineContainer = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  right: 29%;
  padding: ${desktopSize(30)} 0;
  @media all and (max-width: 768px) {
    right: 24.4%;
    padding: ${mobileSize(30)} 0;
  }
`

const VerticalLine = styled.div`
  width: 2px;
  background: ${props => props.theme.color.white};
  height: 100%;
`

/*--- Horizontal Line ---*/

const HorizontalLineContainer = styled.div`
  width: 100%;
  padding: ${desktopSize(30)} ${desktopSize(116)} 0 ${desktopSize(121)};
  @media all and (max-width: 768px) {
    padding: ${mobileSize(30)} ${mobileSize(12)} 0 ${mobileSize(12)};
  }
`

const HorizontalLine = styled.div`
  width: 100%;
  background: ${props => props.theme.color.white};
  height: 2px;
`

/*--- Absolute Images ---*/

const SmallImagePortrait = styled.div`
  position: absolute;
  top: ${desktopSize(551)};
  width: ${desktopSize(162)};
  left: ${desktopSize(198)};
  @media all and (max-width: 768px) {
    top: ${mobileSize(210)};
    width: ${mobileSize(81)};
    left: ${mobileSize(72)};
  }
`

const SmallImageLandscape = styled.div`
  position: absolute;
  top: ${desktopSize(117)};
  width: ${desktopSize(248)};
  left: ${desktopSize(236)};
  @media all and (max-width: 768px) {
    top: ${mobileSize(30)};
    width: ${mobileSize(124)};
    left: ${mobileSize(92)};
  }
`

const BigImagePortrait = styled.div`
  position: absolute;
  top: ${desktopSize(158)};
  width: ${desktopSize(247)};
  right: ${desktopSize(123)};
  @media all and (max-width: 768px) {
    top: ${mobileSize(58)};
    width: ${mobileSize(123)};
    right: ${mobileSize(0)};
  }
`

/*-----------------------------
Component
-----------------------------*/

class History extends React.Component {
  constructor(props) {
    super(props)
    // State
    this.state = {}
  }

  componentDidMount() {}

  /*-----------------------------
  Render
  -----------------------------*/

  render() {
    return (
      <Wrapper>
        <Main>
          <ContentContainer>
            <Content className="wysiwyg">
              {ReactHtmlParser(this.props.data.text)}
            </Content>
          </ContentContainer>
          <HorizontalLineContainer>
            <HorizontalLine />
          </HorizontalLineContainer>
          <SmallImagePortrait>
            <Img
              fluid={
                this.props.data.small_portrait_image.localFile.childImageSharp
                  .fluid
              }
              alt={this.props.data.small_portrait_image.alt_text}
            />
          </SmallImagePortrait>
          <SmallImageLandscape>
            <Img
              fluid={
                this.props.data.landscape_image.localFile.childImageSharp.fluid
              }
              alt={this.props.data.landscape_image.alt_text}
            />
          </SmallImageLandscape>
          <BigImagePortrait>
            <Img
              fluid={
                this.props.data.large_portrait_image.localFile.childImageSharp
                  .fluid
              }
              alt={this.props.data.large_portrait_image.alt_text}
            />
          </BigImagePortrait>
        </Main>
        <VerticalLineContainer>
          <VerticalLine />
        </VerticalLineContainer>
      </Wrapper>
    )
  }
}

export default History
