// Libraries

import React from "react"
import Img from "gatsby-image"

// Components

// Helpers

import { mobileSize, desktopSize } from "../../helpers/sizeCalculations"

// Styles

import styled from "styled-components"

/*-----------------------------
Styles
-----------------------------*/

const Wrapper = styled.div`
  position: relative;
`

/*--- Main ---*/

const MainContainer = styled.div`
  display: flex;
  padding: 24.65vw 0 0 0;
  @media all and (max-width: 768px) {
    display: block;
    padding: ${mobileSize(355)} 0 0 ${mobileSize(22)};
    width: 75.2%;
  }
`

// Quote

const QuoteContainer = styled.div`
  width: 52.4%;
  display: flex;
  justify-content: flex-end;
  padding: 0 ${desktopSize(28)} 0 0;
  @media all and (max-width: 768px) {
    width: 100%;
    padding: 0 ${mobileSize(11)} 0 0;
  }
`

const Quote = styled.div`
  width: ${desktopSize(442)};
  text-align: right;
  margin: 0;
  @media all and (max-width: 768px) {
    width: 100%;
  }
`

// Author

const Author = styled.p`
  font-size: ${desktopSize(20)};
  line-height: 1;
  font-family: "gt-sectra-fine-regular";
  margin: ${desktopSize(40)} 0 0 0;
  @media all and (max-width: 768px) {
    font-size: ${mobileSize(20)};
    margin: ${mobileSize(40)} 0 0 0;
  }
`

/*--- Image ---*/

const QuoteImage = styled.div`
  position: absolute;
  width: ${desktopSize(277)};
  right: ${desktopSize(315)};
  top: ${desktopSize(95)};
  @media all and (max-width: 768px) {
    width: ${mobileSize(139)};
    right: ${mobileSize(54)};
    top: ${mobileSize(106)};
  }
`

/*--- Horizontal Line ---*/

const HorizontalLineContainer = styled.div`
  width: 100%;
  padding: ${desktopSize(30)} ${desktopSize(105)} 0 ${desktopSize(132)};
  @media all and (max-width: 768px) {
    padding: ${mobileSize(30)} ${mobileSize(12)} 0 ${mobileSize(12)};
  }
`

const HorizontalLine = styled.div`
  width: 100%;
  background: ${props => props.theme.color.white};
  height: 2px;
`

/*--- Vertical Line ---*/

const VerticalLineContainer = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  right: 47.6%;
  padding: ${desktopSize(30)} 0;
  @media all and (max-width: 768px) {
    right: 24.5%;
    padding: ${mobileSize(30)} 0;
  }
`

const VerticalLine = styled.div`
  width: 2px;
  background: ${props => props.theme.color.white};
  height: 100%;
`

/*-----------------------------
Component
-----------------------------*/

class QuoteOne extends React.Component {
  constructor(props) {
    super(props)
    // State
    this.state = {}
  }

  componentDidMount() {}

  /*-----------------------------
  Render
  -----------------------------*/

  render() {
    return (
      <Wrapper>
        <MainContainer>
          <QuoteContainer>
            <Quote className="quote">
              {this.props.data.quote}
              <Author>{this.props.data.citation}</Author>
            </Quote>
          </QuoteContainer>
        </MainContainer>
        <QuoteImage>
          <Img
            fluid={this.props.data.image.localFile.childImageSharp.fluid}
            alt={this.props.data.image.alt_text}
          />
        </QuoteImage>
        <HorizontalLineContainer>
          <HorizontalLine />
        </HorizontalLineContainer>
        <VerticalLineContainer>
          <VerticalLine />
        </VerticalLineContainer>
      </Wrapper>
    )
  }
}

export default QuoteOne
