// Libraries

import React from "react"
import ReactHtmlParser from "react-html-parser"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import Img from "gatsby-image"

// Components

import ArrowLeft from "../../icons/arrow-left.svg"
import ArrowRight from "../../icons/arrow-right.svg"

// Helpers

import { mobileSize, desktopSize } from "../../helpers/sizeCalculations"

// Styles

import styled from "styled-components"

/*-----------------------------
Styles
-----------------------------*/

const Wrapper = styled.div`
  position: relative;
`

/*--- Main ---*/

const Main = styled.div`
  position: relative;
  display: flex;
  padding: ${desktopSize(30)} 0;
  @media all and (max-width: 768px) {
    padding: ${mobileSize(55)} 0 ${mobileSize(62)};
  }
`

/*--- Header ---*/

const HeaderColumn = styled.div`
  width: ${props =>
    (props.chapter === "two" && "41.5%") ||
    (props.chapter === "three" && "37.5%")};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media all and (max-width: 768px) {
    width: 100%;
    padding: 0 ${mobileSize(29)};
    justify-content: center;
  }
`

const HeaderContentContainer = styled.div`
  width: ${desktopSize(355)};
  padding-right: ${desktopSize(34)};
  @media all and (max-width: 768px) {
    width: 100%;
    padding-right: 0;
  }
`

const HeaderTitle = styled.h3`
  text-align: center;
  margin-bottom: 0;
`

const TitleContainer = styled.div`
  position: relative;
  height: ${desktopSize(432)};

  @media all and (max-width: 768px) {
    height: ${mobileSize(400)};
  }
`

const TitleAbsoluteContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;

  &.cross-fade-enter {
    opacity: 0;
  }
  &.cross-fade-enter-active {
    opacity: 1;
    transition: opacity 1s ease-in-out;
  }
  &.cross-fade-exit {
    opacity: 1;
  }
  &.cross-fade-exit-active {
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
`

const Category = styled.h5`
  text-align: center;
  width: 100%;
`

const Title = styled.h2`
  text-align: center;
  width: 100%;
  margin-bottom: 0;
`

const ReadMoreMobile = styled.p`
  display: none;
  text-align: center;
  width: 100%;
  margin: 30px 0 0;
  @media all and (max-width: 768px) {
    display: block;
  }
`

const ReadMoreDesktop = styled.p``

// Arrow Nav

const ArrowNav = styled.div`
  display: flex;
  justify-content: center;
`

const ArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${desktopSize(68)};
  height: ${desktopSize(68)};
  border-radius: 50%;
  border: 2px solid;
  cursor: pointer;
  svg {
    width: ${desktopSize(40)};
    @media all and (max-width: 768px) {
      width: ${mobileSize(40)};
    }
  }
  .arrow-svg-outer {
    stroke: ${props => props.theme.color.burgundy};
  }
  .arrow-svg-stroke {
    stroke-width: 2;
  }
  @media all and (max-width: 768px) {
    width: ${mobileSize(68)};
    height: ${mobileSize(68)};
  }
`

const ArrowContainerRight = styled(ArrowContainer)`
  opacity: 1;
`

const ArrowContainerLeft = styled(ArrowContainer)`
  margin-right: ${desktopSize(20)};
  opacity: 1;
  @media all and (max-width: 768px) {
    margin-right: ${mobileSize(20)};
  }
`

/*--- Content ---*/

const ContentColumn = styled.div`
  position: relative;
  width: ${props =>
    (props.chapter === "two" && "32%") ||
    (props.chapter === "three" && "33.2%")};
  display: flex;
  align-items: flex-end;
  @media all and (max-width: 768px) {
    display: none;
  }
`

const Content = styled.div`
  padding: ${props =>
    (props.chapter === "two" &&
      `0 ${desktopSize(61.6)} 0 ${desktopSize(33)}`) ||
    (props.chapter === "three" &&
      `0 ${desktopSize(35.6)} 0 ${desktopSize(34)}`)};
  width: 100%;
  position: absolute;
  bottom: 0;

  &.cross-fade-enter {
    opacity: 0;
  }
  &.cross-fade-enter-active {
    opacity: 1;
    transition: opacity 1s;
  }
  &.cross-fade-exit {
    opacity: 1;
  }
  &.cross-fade-exit-active {
    opacity: 0;
    transition: opacity 1s;
  }
`

/*--- Image ---*/

const ImageColumn = styled.div`
  position: relative;
  width: ${props =>
    (props.chapter === "two" && "26.5%") ||
    (props.chapter === "three" && "29.3%")};
  height: 52.03vw;
  .images-container {
    height: 100%;
    width: 100%;
  }
  @media all and (max-width: 768px) {
    display: none;
  }
`

const LongImagePortrait = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  &.cross-fade-enter {
    opacity: 0;
  }
  &.cross-fade-enter-active {
    opacity: 1;
    transition: opacity 1s;
  }
  &.cross-fade-exit {
    opacity: 1;
  }
  &.cross-fade-exit-active {
    opacity: 0;
    transition: opacity 1s;
  }

  .gatsby-image-wrapper {
    height: 100%;
  }
`

/*--- Vertical Line ---*/

const VerticalLineContainer = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  left: ${props =>
    (props.chapter === "two" && "41.5%") ||
    (props.chapter === "three" && "37.5%")};
  padding: ${desktopSize(30)} 0;
  @media all and (max-width: 768px) {
    display: none;
  }
`

const VerticalLine = styled.div`
  width: 2px;
  background: ${props => props.theme.color.white};
  height: 100%;
`

/*--- Horizontal Line ---*/

const HorizontalLineContainer = styled.div`
  width: 100%;
  padding: ${props =>
    (props.chapter === "two" &&
      `${desktopSize(0)} ${desktopSize(116)} 0 ${desktopSize(121)}`) ||
    (props.chapter === "three" &&
      `${desktopSize(0)} ${desktopSize(59)} 0 ${desktopSize(60)}`)};
  @media all and (max-width: 768px) {
    padding: ${props =>
      (props.chapter === "two" &&
        `${mobileSize(0)} ${mobileSize(12)} 0 ${mobileSize(12)}`) ||
      (props.chapter === "three" &&
        `${mobileSize(0)} ${mobileSize(12)} 0 ${mobileSize(12)}`)};
  }
`

const HorizontalLine = styled.div`
  width: 100%;
  background: ${props => props.theme.color.white};
  height: 2px;
`

/*-----------------------------
Component
-----------------------------*/

class ThreePanelCarousel extends React.Component {
  constructor(props) {
    super(props)
    // State
    this.state = {
      currentSlide: 0,
    }
  }

  /*-----------------------------
  Methods
  -----------------------------*/

  // Next Slide

  nextSlide = () => {
    let currentSlideNumber =
      this.state.currentSlide === this.props.slides.length - 1
        ? 0
        : this.state.currentSlide + 1

    this.setState({
      currentSlide: currentSlideNumber,
    })
  }

  // Previous Slide

  previousSlide = () => {
    let activeSlideNumber =
      this.state.currentSlide === 0
        ? this.props.slides.length - 1
        : this.state.currentSlide - 1

    this.setState({
      currentSlide: activeSlideNumber,
    })
  }

  /*-----------------------------
  Render
  -----------------------------*/

  render() {
    // Current Slide Details
    const currentSlide = this.props.slides[this.state.currentSlide]
    const title = currentSlide.custom.title
    const content = currentSlide.custom.excerpt
    const category = currentSlide.custom.categories
      ? currentSlide.custom.categories[0]
      : null
    const postType = currentSlide.custom.current_investment_post
    const image = currentSlide.custom.featuredImageLarge
      ? currentSlide.custom.featuredImageLarge.localFile.childImageSharp.fluid
      : null
    const link = `/${
      postType === "yes" ? "current-investments" : "previous-investments"
    }?slug=${currentSlide.slug}&readMore=true`
    return (
      <Wrapper>
        <Main>
          <HeaderColumn chapter={this.props.chapter}>
            <HeaderContentContainer>
              <HeaderTitle>{this.props.headerTitle}</HeaderTitle>
              <TitleContainer>
                <TransitionGroup>
                  <CSSTransition
                    key={this.state.currentSlide}
                    timeout={{ enter: 1000, exit: 100 }}
                    classNames="cross-fade"
                  >
                    <TitleAbsoluteContainer className="wysiwyg">
                      {category && <Category>{category}</Category>}
                      <Title>{ReactHtmlParser(title)}</Title>
                      {link && (
                        <ReadMoreMobile>
                          <a href={link} aria-label="Read More">
                            Read more
                          </a>
                        </ReadMoreMobile>
                      )}
                    </TitleAbsoluteContainer>
                  </CSSTransition>
                </TransitionGroup>
              </TitleContainer>
              <ArrowNav>
                <ArrowContainerLeft onClick={this.previousSlide}>
                  <ArrowLeft />
                </ArrowContainerLeft>
                <ArrowContainerRight onClick={this.nextSlide}>
                  <ArrowRight />
                </ArrowContainerRight>
              </ArrowNav>
            </HeaderContentContainer>
          </HeaderColumn>
          <ContentColumn chapter={this.props.chapter}>
            <TransitionGroup>
              <CSSTransition
                key={this.state.currentSlide}
                timeout={1000}
                classNames="cross-fade"
              >
                <Content
                  className="wysiwyg small-body"
                  chapter={this.props.chapter}
                >
                  <p>{ReactHtmlParser(content)}</p>
                  {link && (
                    <ReadMoreDesktop>
                      <a href={link} aria-label="Read More">
                        Read more
                      </a>
                    </ReadMoreDesktop>
                  )}
                </Content>
              </CSSTransition>
            </TransitionGroup>
          </ContentColumn>
          <ImageColumn chapter={this.props.chapter}>
            <TransitionGroup className="images-container">
              <CSSTransition
                key={this.state.currentSlide}
                timeout={1000}
                classNames="cross-fade"
              >
                <LongImagePortrait>
                  {image && <Img fluid={image} />}
                </LongImagePortrait>
              </CSSTransition>
            </TransitionGroup>
          </ImageColumn>
        </Main>
        <HorizontalLineContainer chapter={this.props.chapter}>
          <HorizontalLine />
        </HorizontalLineContainer>
        <VerticalLineContainer chapter={this.props.chapter}>
          <VerticalLine />
        </VerticalLineContainer>
      </Wrapper>
    )
  }
}

export default ThreePanelCarousel
