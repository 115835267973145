// Libraries

import React from "react"
import { Link } from "gatsby"
import { TweenLite } from "gsap/TweenMax"
import BezierEasing from "bezier-easing"

// Components

import Logo from "../../icons/logo.svg"

// Helpers

import { mobileSize, desktopSize } from "../../helpers/sizeCalculations"

// Styles

import styled from "styled-components"

/*-----------------------------
Styles
-----------------------------*/

const Wrapper = styled.footer`
  height: ${desktopSize(382)};
  position: relative;
  bottom: 0;
  right: 0;
  width: 100%;
  color: ${props => props.theme.color.pink};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${desktopSize(30)} 0;
  z-index: -1;
  opacity: 0;

  @supports (position: sticky) {
    position: sticky;
  }

  @supports (-ms-ime-align: auto) {
    position: relative;
  }

  @media all and (max-width: 768px) {
    /*Kept for reference as spacing is custom. Will be removed once the footer UI/UX changes are approved*/
    /* padding: ${mobileSize(30)} 0;
    height: ${mobileSize(368)}; */
    padding: ${mobileSize(28)} 0;
    background-color: ${props => props.theme.color.burgundy};
    height: ${mobileSize(500)};
    justify-content: flex-start;
    max-height: 100vh; /*To avoid issues in landscape mode*/
  }
`

/*--- Top ---*/

const Top = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 ${desktopSize(74)} 0 0;

  @media all and (max-width: 768px) {
    margin-bottom: ${mobileSize(60)};
  }
`

const LogoWrapper = styled.div`
  svg {
    display: block;
    width: ${desktopSize(162)};
    height: auto;
    @media all and (max-width: 768px) {
      width: ${mobileSize(168)};
    }
  }

  .svg-fill {
    fill: ${props => props.theme.color.pink};
  }
`

/*--- Bottom ---*/

const Bottom = styled.div`
  display: flex;
  justify-content: flex-end;
  @media all and (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 0 0 ${mobileSize(12)};
  }
`

const LinkGroup = styled.div`
  margin-right: ${desktopSize(100)};

  &:first-child {
    margin-right: ${desktopSize(148)};
  }

  @media all and (max-width: 768px) {
    margin-right: ${mobileSize(0)};

    &:first-child {
      margin-right: ${desktopSize(0)};
    }
  }

  span {
    @media all and (max-width: 768px) {
      white-space: nowrap;
    }
  }
`

const LinkListPrimary = styled.div`
  font-size: ${desktopSize(21)};
  line-height: 1.619;
  font-family: "gt-walsheim-regular";
  a {
    display: block;
    text-decoration: none;

    @media all and (max-width: 768px) {
      margin-bottom: ${mobileSize(4)};
    }

    /* set hover effects for desktops */
    @media all and (min-width: 769px) {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media all and (max-width: 768px) {
    font-size: ${mobileSize(22)};
    /*Kept for reference as spacing is custom. Will be removed once the footer UI/UX changes are approved*/
    /* margin-bottom: ${mobileSize(20)}; */
    margin-bottom: ${mobileSize(31)};
  }
`

const LinkListSecondary = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: ${desktopSize(16)};
  line-height: 1.68;
  height: 100%;
  font-family: "gt-walsheim-regular";

  a {
    display: block;
    text-decoration: none;

    @media all and (max-width: 768px) {
      margin-bottom: ${mobileSize(9)};
    }
  }

  @media all and (max-width: 768px) {
    font-size: ${mobileSize(18)};
  }
`

const LinkListSecondaryTop = styled.div`
  /* set hover effects for desktops */
  @media all and (min-width: 769px) {
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
`

const LinkListSecondaryBottom = styled.div`
  @media all and (max-width: 768px) {
    margin-top: ${mobileSize(10)};
  }
`

/*-----------------------------
Component
-----------------------------*/

class Footer extends React.Component {
  constructor(props) {
    super(props)
    // State
    // Refs
    this.wrapper = React.createRef()
  }

  /*-----------------------------
  Lifecycle
  -----------------------------*/

  componentDidMount() {
    // Transition the chapter into the screen on page load.
    if (this.props.animationStatus === "entered") {
      this.animateEntrance()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.animationStatus !== prevProps.animationStatus) {
      // Perform different animations based on the status of the current page transition.
      // Only animation for entering and exiting. The entered status should only trigger our animation on initial mount / first ever page load.
      switch (this.props.animationStatus) {
        case "entering":
          this.animateEntrance()
          break
        case "exiting":
          this.animateExit()
          break
        default:
      }
    }
  }

  /*-----------------------------
  Animations
  -----------------------------*/

  // Entering From A Different Route

  animateEntrance() {
    // Show the footer after all the chapters have animated in (hence the delay)
    TweenLite.fromTo(
      this.wrapper.current,
      1,
      { opacity: 0 },
      {
        ease: BezierEasing(0.25, 0.1, 0.25, 1),
        opacity: 1,
        delay: 3,
      }
    )
  }

  // Exiting To Another Route

  animateExit() {
    TweenLite.fromTo(
      this.wrapper.current,
      1,
      { opacity: 1 },
      {
        ease: BezierEasing(0.25, 0.1, 0.25, 1),
        opacity: 0,
      }
    )
  }

  /*-----------------------------
  Render
  -----------------------------*/

  render() {
    return (
      <Wrapper ref={this.wrapper}>
        <Top>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
        </Top>
        <Bottom>
          <LinkGroup>
            <LinkListPrimary>
              <Link to="/larry-ellisons-story">Larry Ellison's story</Link>
              <Link to="/current-investments">Current Investments</Link>
              <Link to="/previous-investments">Previous Investments</Link>
              <Link to="/contact-us">Contact Us</Link>
            </LinkListPrimary>
          </LinkGroup>
          <LinkGroup>
            <LinkListSecondary>
              <LinkListSecondaryTop>
                <Link to="/terms-of-use">Terms of use</Link>
                <Link to="/privacy-policy">Privacy policy</Link>
              </LinkListSecondaryTop>
              <LinkListSecondaryBottom>
                <span>© Copyright {new Date().getFullYear()}</span>
              </LinkListSecondaryBottom>
            </LinkListSecondary>
          </LinkGroup>
        </Bottom>
      </Wrapper>
    )
  }
}

export default Footer
