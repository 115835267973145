// Libraries

import React from "react"
import ReactHtmlParser from "react-html-parser"
import Img from "gatsby-image"

// Components

// Helpers

import { mobileSize, desktopSize } from "../../helpers/sizeCalculations"

// Styles

import styled from "styled-components"

/*-----------------------------
Styles
-----------------------------*/

const Wrapper = styled.div`
  position: relative;
`

/*--- Main ---*/

const Main = styled.div`
  position: relative;
`

const ContentContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: ${desktopSize(235)} ${desktopSize(35)} 0 0;
  width: 67.6%;

  @media all and (max-width: 768px) {
    width: 75%;
    padding: ${mobileSize(357)} ${mobileSize(12)} 0 ${mobileSize(12)};
  }
`

const Content = styled.div`
  width: ${desktopSize(323)};
  text-align: left;
  margin: 0;
  float: right;

  @media all and (max-width: 768px) {
    width: 100%;
  }
`

/*--- Vertical Line ---*/

const VerticalLineContainer = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  right: 32.3%;
  padding: ${desktopSize(30)} 0;
  @media all and (max-width: 768px) {
    right: 25%;
    padding: ${mobileSize(30)} 0;
  }
`

const VerticalLine = styled.div`
  width: 2px;
  background: ${props => props.theme.color.white};
  height: 100%;
`

/*--- Horizontal Line ---*/

const HorizontalLineContainer = styled.div`
  width: 100%;
  padding: ${desktopSize(30)} ${desktopSize(59)} 0 ${desktopSize(60)};
  @media all and (max-width: 768px) {
    padding: ${mobileSize(30)} ${mobileSize(12)} 0 ${mobileSize(12)};
  }
`

const HorizontalLine = styled.div`
  width: 100%;
  background: ${props => props.theme.color.white};
  height: 2px;
`

/*--- Absolute Images ---*/

const SmallImagePortraitOne = styled.div`
  position: absolute;
  bottom: ${desktopSize(111)};
  width: ${desktopSize(212)};
  left: ${desktopSize(10)};

  @media all and (max-width: 768px) {
    width: ${mobileSize(106)};
    left: ${mobileSize(16)};
    bottom: auto;
    top: ${mobileSize(85)};
  }
`

const SmallImagePortraitTwo = styled.div`
  position: absolute;
  top: ${desktopSize(86)};
  width: ${desktopSize(187)};
  right: ${desktopSize(184)};

  @media all and (max-width: 768px) {
    width: ${mobileSize(94)};
    right: ${mobileSize(16)};
    top: ${mobileSize(60)};
  }
`

const SmallImageLandscape = styled.div`
  position: absolute;
  bottom: ${desktopSize(69)};
  width: ${desktopSize(213)};
  right: ${desktopSize(34)};

  @media all and (max-width: 768px) {
    width: ${mobileSize(106)};
    left: ${mobileSize(144)};
    right: auto;
    bottom: auto;
    top: ${mobileSize(250)};
  }
`

/*-----------------------------
Component
-----------------------------*/

class Opportunities extends React.Component {
  constructor(props) {
    super(props)
    // State
    this.state = {}
  }

  componentDidMount() {}

  /*-----------------------------
  Render
  -----------------------------*/

  render() {
    return (
      <Wrapper>
        <Main>
          <ContentContainer>
            <Content className="wysiwyg">
              {ReactHtmlParser(this.props.data.text)}
            </Content>
          </ContentContainer>
          <HorizontalLineContainer>
            <HorizontalLine />
          </HorizontalLineContainer>
          <SmallImagePortraitOne>
            <Img
              fluid={
                this.props.data.portrait_image_1.localFile.childImageSharp.fluid
              }
              alt={this.props.data.portrait_image_1.alt_text}
            />
          </SmallImagePortraitOne>
          <SmallImagePortraitTwo>
            <Img
              fluid={
                this.props.data.portrait_image_2.localFile.childImageSharp.fluid
              }
              alt={this.props.data.portrait_image_2.alt_text}
            />
          </SmallImagePortraitTwo>
          <SmallImageLandscape>
            <Img
              fluid={
                this.props.data.landscape_image.localFile.childImageSharp.fluid
              }
              alt={this.props.data.landscape_image.alt_text}
            />
          </SmallImageLandscape>
        </Main>
        <VerticalLineContainer>
          <VerticalLine />
        </VerticalLineContainer>
      </Wrapper>
    )
  }
}

export default Opportunities
