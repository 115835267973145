// Libraries

import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import { TweenLite } from "gsap/TweenMax"
import BezierEasing from "bezier-easing"
import ScrollToPlugin from "gsap/ScrollToPlugin"
import { debounce } from "lodash"
import { isDesktop } from "../helpers/mediaQueryChecks"

// Components

import SEO from "../components/global/SEO"
import StickyNav from "../components/global/StickyNav"
import ChapterOne from "../components/homepage/ChapterOne"
import ChapterTwo from "../components/homepage/ChapterTwo"
import ChapterThree from "../components/homepage/ChapterThree"
import CookieConsent from "../components/global/CookieConsent"

// Styles

import styled from "styled-components"

const plugins = [ScrollToPlugin] // eslint-disable-line no-unused-vars

/*-----------------------------
Styles
-----------------------------*/

const HomeWrapper = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
`

const HomeChapter = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: ${props => props.zIndex};
  overflow-y: scroll;
  overflow-x: hidden;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  pointer-events: ${props => (props.isActive ? "all" : "none")};
  outline: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

/*-----------------------------
Component
-----------------------------*/

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    // Refs
    this.pageWrapper = React.createRef()
    this.homeChapterOne = React.createRef()
    this.homeChapterTwo = React.createRef()
    this.homeChapterThree = React.createRef()
    // State
    this.state = {
      activeChapter: 1,
      chapters: [
        { node: this.homeChapterOne, color: "#5e6e5c" },
        { node: this.homeChapterTwo, color: "#ffb662" },
        { node: this.homeChapterThree, color: "#ff9999" },
      ],
      transitioningToPreviousChapter: false,
      scrolledPastInitial: false,
      scrollDirection: "up",
      lastScroll: [0, 0, 0],
      windowHeight: "",
    }
    // Method Declaration / Binding
    this.handleChapterScroll = debounce(this.handleChapterScroll.bind(this), 3)
    this.handleWindowResize = this.handleWindowResize.bind(this)
  }

  componentDidMount() {
    // Set initial chapter focus
    this.homeChapterOne.current.focus()
    // Set the window height
    this.setState({
      windowHeight: window.innerHeight,
    })
    // Window Resize
    window.addEventListener("resize", this.handleWindowResize)
  }

  componentWillUnmount() {
    // Remove the window resize event
    window.removeEventListener("resize", this.handleWindowResize)
  }

  componentDidUpdate(prevProps) {
    if (this.props.animationStatus !== prevProps.animationStatus) {
      // Perform different animations based on the status of the current page transition.
      // Only animation for entering and exiting. The entered status should only trigger our animation on initial mount / first ever page load.
      switch (this.props.animationStatus) {
        case "entering":
          this.animateEntrance()
          break
        case "exiting":
          this.animateExit()
          break
        default:
      }
    }
  }

  // refocus on back to home page to navigate through arrow keys
  componentWillReceiveProps() {
    // set focus on container when back forth of pages
    this.homeChapterOne && this.homeChapterOne.current.focus()
  }

  /*-----------------------------
  Animations
  -----------------------------*/

  // Entering From A Different Route

  animateEntrance() {
    // Perform the entrance transition.
    const pageWrapperNode = this.pageWrapper.current
    // Set a higher z-index so it come in at the appropriate level.
    TweenLite.set(pageWrapperNode, { zIndex: 1 })
    // Transition from visibly hidden to fully shown. Delay the transition so the exiting route has time to animate.
    TweenLite.fromTo(
      pageWrapperNode,
      0.1,
      { opacity: 0 },
      {
        ease: BezierEasing(0.25, 0.1, 0.25, 1),
        opacity: 1,
        delay: 1.5,
      }
    )
  }

  // Exiting To Another Route

  animateExit() {
    const pageWrapperNode = this.pageWrapper.current
    // Transition the page content to visibly hidden. Delay the overall hiding effect so the interior elements of the
    // page have time to transition out.
    TweenLite.to(pageWrapperNode, 0.1, {
      ease: BezierEasing(0.25, 0.1, 0.25, 1),
      opacity: 0,
      delay: 1.5,
    })
    // Lower the z-index so the incoming page content can be interacted with.
    TweenLite.set(pageWrapperNode, { delay: 1.5, zIndex: -10 })
  }

  /*-----------------------------
  Handle Chapter Scroll
  ---
  Handles the transition of scrolling to/from each chapter on the homepage.
  Chapters are positioned absolute and layered so we need js to force a transition from 
  one chapter to the next.
  -----------------------------*/

  handleChapterScroll(chapterIndex, e) {
    // Only perform the scroll calculations and activations if this is the active chapter.
    // Should help with performance and not triggering a chapter change in a window resize event from a chapter that isn't active.
    if (chapterIndex === this.state.activeChapter) {
      // Get the chapter node that we are current scrolling on.
      const chapterZeroIndex = chapterIndex - 1
      const chapterNode = this.state.chapters[chapterZeroIndex].node.current
      // Capture its height.
      const chapterHeight = chapterNode.scrollHeight - window.innerHeight
      // Save if its the first or last chapter.
      const firstChapter = chapterIndex === 1 ? true : false
      const lastChapter =
        chapterIndex === this.state.chapters.length ? true : false
      // Previous Chapter
      let previousChapterNode
      let previousChapterHeight
      if (this.state.chapters[chapterZeroIndex - 1]) {
        previousChapterNode = this.state.chapters[chapterZeroIndex - 1].node
          .current
        previousChapterHeight = previousChapterNode.scrollHeight
      }
      // Next Chapter
      let nextChapterNode
      if (this.state.chapters[chapterZeroIndex + 1]) {
        nextChapterNode = this.state.chapters[chapterZeroIndex + 1].node.current
      }

      // Activate the previous chapter after scrolled to the top of the current.
      // Not enabled for the first chapter because we don't want it to get stuck at the top.

      if (!firstChapter) {
        if (chapterNode.scrollTop <= 0) {
          // Indicate that we are transitioning to the previous chapter.
          this.setState({
            transitioningToPreviousChapter: true,
          })
          // Set the previous chapter as active.
          this.setState({
            activeChapter: chapterIndex - 1,
          })
          // Scroll the previous chapter into view for the user.
          TweenLite.to(previousChapterNode, 1, {
            scrollTo: `${previousChapterHeight}` - window.innerHeight - 300,
          })
          // Focus the previous chapter
          previousChapterNode.focus()
          // After 5ms then indicate that we have already transitioned to the previous chapter.
          window.setTimeout(() => {
            this.setState({
              transitioningToPreviousChapter: false,
            })
          }, 500)
        }
      }

      // Activate the next chapter after scrolling past the current.
      // Not enabled for the last chapter because we don't want to get stuck at the bottom.

      if (!lastChapter) {
        // Check to make sure we aren't actively transitioning to a previous chapter so the scroll motion doesn't get stuck.
        // Add 1 to the scroll top because some browsers will estimate a decimal value for the last scroll that is less than the chapter height.
        if (
          chapterNode.scrollTop + 1 >= chapterHeight &&
          !this.state.transitioningToPreviousChapter
        ) {
          this.setState({
            activeChapter: chapterIndex + 1,
          })
          // Scroll the next chapter into view for the user.
          TweenLite.to(nextChapterNode, 1, {
            scrollTo: `${isDesktop ? 100 : 25}`,
          })
          // Focus the next chapter
          nextChapterNode.focus()
        }
      }

      // Check to see how far we've scrolled in the first chapter. Helps to determine any element changes that
      // should happen after the initial scroll.

      if (chapterIndex === 1) {
        this.setState({
          scrolledPastInitial: chapterNode.scrollTop > 100 ? true : false,
        })
      }

      // Set the scroll direction based on the direction we are scrolling within a specific chapter.

      const currentScrollTopVal = chapterNode.scrollTop
      // If the last scroll position is further down the page than the current scroll position then we are moving up.
      if (this.state.lastScroll[chapterIndex - 1] > currentScrollTopVal) {
        let lastScroll = { ...this.state.lastScroll }
        lastScroll[chapterIndex - 1] = currentScrollTopVal
        this.setState({
          scrollDirection: "up",
          lastScroll,
        })
      }

      // Else the last scroll position is higher up the page than the current scroll position then we are moving down.
      else if (this.state.lastScroll[chapterIndex - 1] < currentScrollTopVal) {
        let lastScroll = { ...this.state.lastScroll }
        lastScroll[chapterIndex - 1] = currentScrollTopVal
        this.setState({
          scrollDirection: "down",
          lastScroll,
        })
      }
    }
  }

  /*-----------------------------
  Window Resize
  -----------------------------*/

  handleWindowResize(e) {
    /*-----------------------------
    Chapter Harnessing
    ---
    Harness chapters that have already been scrolled past to the bottom of the page so they don't peak into view
    during window resize.
    -----------------------------*/

    // Check each chapter
    const chapters = this.state.chapters
    chapters.forEach((chapter, index) => {
      // Save the one index of this chapter. That's how we save the chapters in state.
      const oneIndex = index + 1
      const node = chapter.node.current
      // If the node exists (sometimes it won't be captured in a resize event, takes time to bubble)
      // and if this is a previous chapter then we want to keep its position harnessed to the bottom of the page.
      if (node && oneIndex < this.state.activeChapter) {
        // Harness the scroll to the bottom of the page so the chapter doesn't peak into view.
        node.scrollTop = node.scrollHeight
      }
    })

    /*-----------------------------
    Window Height
    -----------------------------*/

    // Set the window height
    this.setState({
      windowHeight: window.innerHeight,
    })
  }

  /*-----------------------------
  Render
  -----------------------------*/

  render() {
    const homepageData = this.props.data.wordpressPage.custom
    const allInvestmentPosts = this.props.data.allWordpressWpInvestment.nodes
    return (
      <HomeWrapper ref={this.pageWrapper}>
          <Helmet>                 
            <meta name="description" content="The Larry Ellison Foundation supports organizations and businesses which set out to make a difference in the world." />
          </Helmet>
        <SEO title="Home" />
        <StickyNav
          animationStatus={this.props.animationStatus}
          scrollDirection={this.state.scrollDirection}
          scrolledPastInitial={this.state.scrolledPastInitial}
          navColor={this.state.chapters[this.state.activeChapter - 1].color}
        />
        <HomeChapter
          ref={this.homeChapterOne}
          isActive={this.state.activeChapter === 1 ? true : false}
          onScroll={e => this.handleChapterScroll(1, e)}
          zIndex={this.state.activeChapter === 1 ? "4" : "3"}
          tabIndex="0"
        >
          <ChapterOne
            windowHeight={this.state.windowHeight}
            animationStatus={this.props.animationStatus}
            data={homepageData.chapter_1}
          />
        </HomeChapter>
        <HomeChapter
          ref={this.homeChapterTwo}
          isActive={this.state.activeChapter === 2 ? true : false}
          onScroll={e => this.handleChapterScroll(2, e)}
          zIndex={this.state.activeChapter === 2 ? "4" : "2"}
          tabIndex="0"
        >
          <ChapterTwo
            animationStatus={this.props.animationStatus}
            chapterIsActive={this.state.activeChapter === 2 ? true : false}
            data={homepageData.chapter_2}
            allInvestmentPosts={allInvestmentPosts}
            windowHeight={this.state.windowHeight}
          />
        </HomeChapter>
        <HomeChapter
          ref={this.homeChapterThree}
          isActive={this.state.activeChapter === 3 ? true : false}
          onScroll={e => this.handleChapterScroll(3, e)}
          zIndex={this.state.activeChapter === 3 ? "4" : "1"}
          tabIndex="0"
        >
          <ChapterThree
            animationStatus={this.props.animationStatus}
            chapterIsActive={this.state.activeChapter === 3 ? true : false}
            data={homepageData.chapter_3}
            allInvestmentPosts={allInvestmentPosts}
          />
        </HomeChapter>
        <CookieConsent />
      </HomeWrapper>
    )
  }
}

/*-----------------------------
Homepage Content Query
---
The result of the query is stored in this components data prop.
-----------------------------*/

// Chapter Image Fragment
// Composes the image formatting we need for graphql image queries.

export const chapterImage = graphql`
  fragment chapterImage on File {
    childImageSharp {
      fluid(maxWidth: 800) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

// Page Query for all the homepage chapter content.

export const query = graphql`
  query HomePageQuery {
    wordpressPage(slug: { eq: "home" }) {
      custom {
        chapter_1 {
          hero {
            title
            bird_image {
              localFile {
                ...chapterImage
              }
              alt_text
              alt_text
            }
          }
          commitment {
            text
            title
            primary_image {
              localFile {
                ...chapterImage
              }
              alt_text
            }
            secondary_image {
              localFile {
                ...chapterImage
              }
              alt_text
            }
          }
          investment_approach {
            text
            title
            primary_image {
              localFile {
                ...chapterImage
              }
              alt_text
            }
            secondary_image {
              localFile {
                ...chapterImage
              }
              alt_text
            }
          }
          quote_1 {
            quote
            landscape_image {
              localFile {
                ...chapterImage
              }
              alt_text
            }
            portrait_image {
              localFile {
                ...chapterImage
              }
              alt_text
            }
            square_image {
              localFile {
                ...chapterImage
              }
              alt_text
            }
          }
          quote_2 {
            citation
            quote
            image {
              localFile {
                ...chapterImage
              }
              alt_text
            }
          }
        }
        chapter_2 {
          hero {
            title
            bird_image {
              localFile {
                ...chapterImage
              }
              alt_text
            }
          }
          history {
            text
            large_portrait_image {
              localFile {
                ...chapterImage
              }
              alt_text
            }
            small_portrait_image {
              localFile {
                ...chapterImage
              }
              alt_text
            }
            landscape_image {
              localFile {
                ...chapterImage
              }
              alt_text
            }
          }
          investment_carousel {
            title
            investment_posts {
              investment_post
            }
          }
        }
        chapter_3 {
          hero {
            title
            bird_image {
              localFile {
                ...chapterImage
              }
              alt_text
            }
          }
          opportunities {
            text
            portrait_image_1 {
              localFile {
                ...chapterImage
              }
              alt_text
            }
            portrait_image_2 {
              localFile {
                ...chapterImage
              }
              alt_text
            }
            landscape_image {
              localFile {
                ...chapterImage
              }
              alt_text
            }
          }
          investment_carousel {
            title
            investment_posts {
              investment_post
            }
          }
          learning {
            title
            text
            primary_image {
              localFile {
                ...chapterImage
              }
              alt_text
            }
            secondary_image {
              localFile {
                ...chapterImage
              }
              alt_text
            }
          }
        }
      }
    }
    allWordpressWpInvestment {
      nodes {
        custom {
          current_investment_post
          excerpt
          title
          featuredImageLarge {
            localFile {
              ...chapterImage
            }
            alt_text
          }
          categories
        }
        wordpress_id
        slug
      }
    }
  }
`

export default IndexPage
