// Libraries

import React from "react"
import { TweenLite } from "gsap/TweenMax"
import BezierEasing from "bezier-easing"
import ReactHtmlParser from "react-html-parser"
import Img from "gatsby-image"
import vhCheck from "vh-check"

// Components

// Helpers

import { mobileSize, desktopSize } from "../../helpers/sizeCalculations"

// Styles

import styled from "styled-components"

/*-----------------------------
Styles
-----------------------------*/

/*--- Structure ---*/

const Hero = styled.div``

const HeroContainer = styled.div`
  position: relative;
  height: calc(100vh - ${desktopSize(50)});

  @media all and (max-width: 768px) {
    height: calc(100vh - ${props => props.browserBarHeight}px);
  }

  @media all and (max-width: 768px) and (orientation: landscape) {
    height: 200vh;
  }
`

/*--- Main Head ---*/

// Title

const HeroMainTitle = styled.h2`
  position: absolute;
  opacity: 0;
  font-size: ${desktopSize(130)};
  left: ${desktopSize(26)};
  bottom: ${desktopSize(25)};
  transform: translateY(60px);
  margin-bottom: 0;
  @media all and (max-width: 768px) {
    top: ${mobileSize(30)};
    bottom: auto;
    left: ${mobileSize(11)};
  }
`

// Bird

const HeroMainBird = styled.div`
  position: absolute;
  display: block;
  left: ${desktopSize(500)};
  bottom: ${desktopSize(340)};
  img{
    position: relative !important;
    top: 33px !important;
    left: -20px !important;
    width: auto !important;
    height: auto !important;
  }
  @media all and (max-width: 768px) {
    left: 130px;
    bottom: 250px;
    img{
      top: 0px !important;
      left: 0px !important;
    }
    width: ${mobileSize(230)};
    max-width: 400px;
  }
`

/*--- Horizontal Line ---*/

const HorizontalLineContainer = styled.div`
  width: 100%;
  padding: ${desktopSize(30)} ${desktopSize(59)} 0 ${desktopSize(60)};
  @media all and (max-width: 768px) {
    padding: ${mobileSize(30)} ${mobileSize(12)} 0 ${mobileSize(12)};
  }
`

const HorizontalLine = styled.div`
  width: 100%;
  background: ${props => props.theme.color.white};
  height: 2px;
`

/*-----------------------------
Component
-----------------------------*/

class ChapterThreeHero extends React.Component {
  constructor(props) {
    super(props)
    // State
    this.state = {
      browserBarHeight: 0,
    }
    // Refs
    this.heroMainTitle = React.createRef()
    this.heroSideTitle = React.createRef()
  }

  /*-----------------------------
  Lifecycle
  -----------------------------*/

  componentDidMount() {
    // Check viewport height.
    const vhInfo = vhCheck()
    this.setState({
      browserBarHeight: vhInfo.value,
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.chapterIsActive !== prevProps.chapterIsActive) {
      // If the chapter is initially loaded then show the hero elements.
      if (this.props.chapterIsActive === true) {
        TweenLite.to(this.heroMainTitle.current, 1, {
          ease: BezierEasing(0.25, 0.1, 0.25, 1),
          opacity: 1,
          transform: "translateY(0)",
        })
      }
      // Else the chapter isn't initially loaded so hide the hero elements.
      else {
        TweenLite.to(this.heroMainTitle.current, 1, {
          ease: BezierEasing(0.25, 0.1, 0.25, 1),
          opacity: 0,
          transform: "translateY(50px)",
        })
      }
    }
  }

  /*-----------------------------
  Render
  -----------------------------*/

  render() {
    return (
      <Hero>
        <HeroContainer browserBarHeight={this.state.browserBarHeight}>
          <HeroMainTitle ref={this.heroMainTitle}>
            {ReactHtmlParser(this.props.data.title)}
          </HeroMainTitle>
          <HeroMainBird>
            <Img
              fluid={this.props.data.bird_image.localFile.childImageSharp.fluid}
              loading="eager"
              alt={this.props.data.bird_image.alt_text}
            />
          </HeroMainBird>
        </HeroContainer>
        <HorizontalLineContainer>
          <HorizontalLine />
        </HorizontalLineContainer>
      </Hero>
    )
  }
}

export default ChapterThreeHero
