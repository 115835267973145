// Libraries

import React from "react"
import ReactHtmlParser from "react-html-parser"
import Img from "gatsby-image"

// Components

// Helpers

import { mobileSize, desktopSize } from "../../helpers/sizeCalculations"

// Styles

import styled from "styled-components"

/*-----------------------------
Styles
-----------------------------*/

const Wrapper = styled.div`
  position: relative;
`

/*--- Main ---*/

const Main = styled.div`
  position: relative;
`

const QuoteContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: ${desktopSize(351)} ${desktopSize(29)} 0 0;
  width: 79%;

  @media all and (max-width: 768px) {
    width: 75%;
    padding: ${mobileSize(356)} ${mobileSize(10)} 0 0;
  }
`

const Quote = styled.div`
  width: ${desktopSize(455)};
  text-align: right;
  margin: 0;
  float: right;

  @media all and (max-width: 768px) {
    width: ${mobileSize(255)};
  }
`

const Author = styled.p`
  font-size: ${desktopSize(20)};
  line-height: 1;
  font-family: "gt-sectra-fine-regular";
  margin: ${desktopSize(40)} 0 0 0;
  @media all and (max-width: 768px) {
    font-size: ${mobileSize(20)};
    margin: ${mobileSize(40)} 0 0 0;
  }
`

/*--- Vertical Line ---*/

const VerticalLineContainer = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  right: 21%;
  padding: ${desktopSize(30)} 0;
  @media all and (max-width: 768px) {
    right: 25%;
    padding: ${mobileSize(24)} 0 ${mobileSize(31.5)};
  }
`

const VerticalLine = styled.div`
  width: 2px;
  background: ${props => props.theme.color.white};
  height: 100%;
`

/*--- Horizontal Line ---*/

const HorizontalLineContainer = styled.div`
  width: 100%;
  padding: ${desktopSize(30)} ${desktopSize(105)} 0 ${desktopSize(132)};
  @media all and (max-width: 768px) {
    padding: ${mobileSize(30)} ${mobileSize(12)} 0 ${mobileSize(12)};
  }
`

const HorizontalLine = styled.div`
  width: 100%;
  background: ${props => props.theme.color.white};
  height: 2px;
`

/*--- Absolute Images ---*/

const SmallImageSquare = styled.div`
  position: absolute;
  top: ${desktopSize(186)};
  width: ${desktopSize(146)};
  left: ${desktopSize(259)};

  @media all and (max-width: 768px) {
    top: ${mobileSize(21)};
    width: ${mobileSize(73)};
    left: ${mobileSize(123)};
  }
`

const SmallImageLandscape = styled.div`
  position: absolute;
  top: ${desktopSize(377)};
  width: ${desktopSize(287)};
  left: ${desktopSize(89)};

  @media all and (max-width: 768px) {
    top: ${mobileSize(117)};
    width: ${mobileSize(139)};
    left: ${mobileSize(38)};
  }
`

const BigImagePortrait = styled.div`
  position: absolute;
  top: ${desktopSize(123)};
  width: ${desktopSize(245)};
  right: ${desktopSize(28)};

  @media all and (max-width: 768px) {
    top: ${mobileSize(100)};
    width: ${mobileSize(123)};
    right: ${mobileSize(0)};
    left: auto;
  }
`

/*-----------------------------
Component
-----------------------------*/

class QuoteTwo extends React.Component {
  constructor(props) {
    super(props)
    // State
    this.state = {}
  }

  componentDidMount() {}

  /*-----------------------------
  Render
  -----------------------------*/

  render() {
    return (
      <Wrapper>
        <Main>
          <QuoteContainer>
            <Quote className="quote">
              {ReactHtmlParser(this.props.data.quote)}
              <Author>{this.props.quoteData.citation}</Author>
            </Quote>
          </QuoteContainer>
          <HorizontalLineContainer>
            <HorizontalLine />
          </HorizontalLineContainer>
          <SmallImageSquare>
            <Img
              fluid={
                this.props.data.square_image.localFile.childImageSharp.fluid
              }
              alt={this.props.data.square_image.alt_text}
            />
          </SmallImageSquare>
          <SmallImageLandscape>
            <Img
              fluid={
                this.props.data.landscape_image.localFile.childImageSharp.fluid
              }
              alt={this.props.data.landscape_image.alt_text}
            />
          </SmallImageLandscape>
          <BigImagePortrait>
            <Img
              fluid={
                this.props.data.portrait_image.localFile.childImageSharp.fluid
              }
              alt={this.props.data.portrait_image.alt_text}
            />
          </BigImagePortrait>
        </Main>
        <VerticalLineContainer>
          <VerticalLine />
        </VerticalLineContainer>
      </Wrapper>
    )
  }
}

export default QuoteTwo
